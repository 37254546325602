import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/allison-breyfogle.jpg";
  const fullName = "Allison Breyfogle";
  const zoomUrl =
    "https://us02web.zoom.us/j/89513511594?pwd=bndtZEJvc0plMXpVSFdpT0RGMXNXUT09";
  const zoomPhoneNumber = "(778) 907-2071";
  const zoomMeetingId = "895 1351 1594";
  const zoomMeetingPasscode = "892811";
  const birthDate = "May 3, 1951";
  const deathDate = "November 16, 2020";
  const memorialDate = "Saturday December 19th, 2020";
  const memorialTime = "11am PST";
  const inviteText =
    "Allison left us to join her beloved angels on November 16, 2020. As we can’t gather now for the party she would want us to have in her honour, please join us virtually to remember, celebrate her life, and of course raise a glass to all that she brought to our lives. All are welcome.";
  const obituary = `It’s with great sadness but with gratitude for a life well-lived that the family of Allison Breyfogle announce her passing on Monday, November 16, 2020 at the age of 69 years. She passed peacefully in hospice in Kelowna after a long and strong battle with cancer, with her daughter Brooke and sister Karen by her side.
  \nAllison was born in Winnipeg in 1951 and grew up mainly in Morris, MB where the family ran a popular gas station. She moved to Winnipeg as a young woman and then married Bruce in 1970, with their daughter Brooke to come in the same year. In 1974 the young family moved to Toronto, where Allison started her career at Great West Life Insurance (and created a legacy as several more Breyfogles would work there!). In 1982, she moved back to Winnipeg, with Bruce and Brooke following shortly thereafter. She and Bruce amicably divorced in the early 90s, and Allison soon left Winnipeg and her career at GWL to make her way to join her mother, sister and brother in BC and settle in Kelowna, where she lived for the remainder of her life. There, she worked for many years at Glenncoe Transport.
  \nShe loved to travel and loved the sun, as evidenced by many trips to warm and tropical locales such as Jamaica, Barbados, Nassau and Hawaii. She also visited London, Tokyo, the Philippines, visited Brooke in Los Angeles and Phoenix, and she and Bruce famously went to Paris for dinner for their anniversary (they did stay the night!). She was a prolific poet and her spirituality led her later life via connection with her Angels, with whom she communicated and found great strength and direction.
  \nAllison will be forever in the hearts and memories of her daughter Brooke (Paul Goebel), her mother Doreen Houde, brother James (Shirley) Schwark, sister Karen Couture (Randy Takeda), sister-in-law Deb Schwark, ex-husband Bruce Breyfogle, her many nephews, nieces, grand-nephews and -nieces, her aunts, uncles and dozens of cousins in the Downey and Schwark clans, and her countless friends and colleagues. She will be met in the next plane by her father Allan Schwark, stepfather Roland Houde, brother Brian Schwark, her beloved cat Sydney, as well as some great friends who will surely guide her path.`;
  const storiesRecordUrl = "https://stories.afterword.com/f8w6k0vc1";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rn33puhgmosmotl3ea4ysh3hnx66qehifddquf02";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/allison-breyfogle.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
    >
      <p>
        “We’re here for a good time, not a long time. So have a good time, the
        sun can’t shine every day.”
        <br />
        Trooper
      </p>

      <h4>In Lieu of Flowers</h4>
      <p>
        In lieu of flowers or other gifts, donations in her memory can be made
        to the{" "}
        <a
          href="https://www.cancer.ca/en/donate/"
          target="_blank"
          rel="noreferrer"
        >
          Canadian Cancer Society
        </a>
        .
      </p>
      <h4>Glass Remembrances</h4>
      <p>
        If you would like a handmade glass piece with Allison’s ashes from
        Brooke for the cost of postage, submit a request in{" "}
        <a
          href="https://forms.gle/ANJEQiDZW1dBjCwaA"
          target="_blank"
          rel="noreferrer"
        >
          this form
        </a>
        .
      </p>
      <h4 sx={{ marginBottom: "0" }}>
        <i>The Chase</i>
      </h4>
      <p>
        By Allison Breyfogle, 1998
        <br />
        <br />
        I run to catch a glimpse of gold:
        <br />
        My rainbow’s end, my tale untold.
        <br />
        My fingers reach to touch the sky
        <br />
        To feel the blue, the inside cry.
        <br />
        <br />
        I race along life’s busy road
        <br />
        And leave a trench with seed unsowed;
        <br />
        My tendrils reach to feel the sun
        <br />
        For needed warmth, my deed undone.
        <br />
        <br />
        I hunt upon my tiring steed
        <br />
        But find no game to fill my need.
        <br />
        My arrow falls, enchantment gone;
        <br />
        I wonder how the search goes on.
        <br />
        <br />
        I speed into the heart of time
        <br />
        And place my fate on spin of dime;
        <br />
        My mindsong reaches to the stars
        <br />
        To heal my everlasting scars.
        <br />
        <br />
        I grind my heels into the earth
        <br />
        To sudden stop, to a new birth;
        <br />
        My soul beats rhythm to my heart
        <br />
        And orchestrates a brand new start.
        <br />
        <br />
        I melt into the sparkling flow
        <br />
        Of lifetime, then just let go
        <br />
        To let the river carry me
        <br />
        To love that’s neverending, free.
      </p>
    </StoryPage>
  );
};

export default ThisPage;
